import React from "react";
import { useRouter } from "next/router";
import { Today, InsertDriveFile } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  RecommendationsContainer,
  Item,
  StyledButton,
  StyledCritical,
  StyledWarnings,
  StyledImprovements,
  ContentContainer,
} from "./styles";
import {
  ObjectFragment,
  TestFailedStatus,
  TestInstanceFragment,
} from "../../../generated/api-client";

const objectName = (object: ObjectFragment) =>
  [object.description1, object.description2].filter((x) => x.length > 0).join(" - ") + ": ";

const BoringList = ({ items }: { items: TestInstanceFragment[] }) =>
  items.length > 0 ? (
    <ul>
      {items.map((item) => (
        <li key={item.id}>
          {item.object && objectName(item.object)}
          {item.resultTester?.recommendation}
        </li>
      ))}
    </ul>
  ) : null;

interface IRecommendations {
  instances: TestInstanceFragment[];
}

export const Recommendations = ({ instances }: IRecommendations) => {
  const router = useRouter();
  const { render } = router.query;

  const critical = instances
    .filter((ob) => ob.resultTester?.testFailedStatus === TestFailedStatus.Critical)
    .filter((ob) => ob.resultTester?.recommendation?.length);
  const warnings = instances
    .filter((ob) => ob.resultTester?.testFailedStatus === TestFailedStatus.Warning)
    .filter((ob) => ob.resultTester?.recommendation?.length);
  const improvements = instances
    .filter((ob) => ob.resultTester?.testFailedStatus === TestFailedStatus.Improvement)
    .filter((ob) => ob.resultTester?.recommendation?.length);

  const allIsWell = critical.length + warnings.length + improvements.length === 0;

  return (
    <RecommendationsContainer>
      <Grid container sx={{ flexDirection: "column" }}>
        <Grid container sx={{ flexDirection: "row" }}>
          <InsertDriveFile
            color="primary"
            sx={{ fontSize: "36px", marginRight: 1, marginLeft: 3 }}
          />
          <Typography sx={{ fontSize: "24px", fontWeight: "700", letterSpacing: "-0.2px" }}>
            Rekommenderade åtgärder
          </Typography>
        </Grid>
        {allIsWell ? (
          <Typography sx={{ fontSize: "18px", fontWeight: "700", padding: "36px 0", ml: 3 }}>
            Allt är i sin ordning, så ta de lugnt!
          </Typography>
        ) : (
          <ContentContainer>
            <Grid item sx={{ flex: 1, marginLeft: 3, marginRight: 3 }}>
              <Item sx={{ border: 1 }}>
                <StyledCritical variant="h3">{critical.length}</StyledCritical>
                <Typography sx={{ paddingLeft: 2 }} variant="subtitle2">
                  Kritiska åtgärder
                </Typography>
              </Item>
              <BoringList items={critical} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Item sx={{ border: 1 }}>
                <StyledWarnings variant="h3">{warnings.length}</StyledWarnings>
                <Typography sx={{ paddingLeft: 2 }} variant="subtitle2">
                  Varningar
                </Typography>
              </Item>
              <Grid sx={{ marginTop: 4 }}>
                <BoringList items={warnings} />
              </Grid>
            </Grid>
            <Grid item sx={{ flex: 1, marginLeft: 3, marginRight: 3 }}>
              <Item border={1}>
                <StyledImprovements variant="h3">{improvements.length}</StyledImprovements>
                <Typography sx={{ paddingLeft: 2 }} variant="subtitle2">
                  Förbättringar
                </Typography>
              </Item>
              <Grid sx={{ marginTop: 4 }}>
                <BoringList items={improvements} />
              </Grid>
            </Grid>
          </ContentContainer>
        )}
      </Grid>
      {!allIsWell && render !== "iframe" && <ShutUpAndTakeMyMoney />}
    </RecommendationsContainer>
  );
};

const ShutUpAndTakeMyMoney = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid container sx={{ marginLeft: 2, marginTop: 5 }}>
      <StyledButton startIcon={<Today />} variant="contained" onClick={handleOpen}>
        Boka service
      </StyledButton>

      <Tooltip title="Coming soon">
        <span>
          <StyledButton startIcon={<Today />} sx={{ ml: 2 }} disabled variant="contained">
            Beställ åtgärder
          </StyledButton>
        </span>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "400px",
            maxWidth: "80vw",
            maxHeight: "80vw",
            backgroundColor: "background.paper",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Ta det lugnt
            </Typography>
            <Typography variant="h5">Vi löser detta!</Typography>
            <Typography variant="body2">
              <p>
                Vi kikar genast på de förbättringar och åtgärder som behöver göras, och hur vi bäst
                kan se till att de görs så smidigt och kostnadseffektivt som möjligt för dig.
              </p>
              <p>Vi återkopplar inom kort med kostnadsförslag och bokar in detta.</p>
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleClose}>
              Tack!
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Grid>
  );
};
