import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  paddingTop: 20,
}));

export const StyledNote = styled(Typography)(({ theme }) => ({
  paddingTop: 10,
}));
