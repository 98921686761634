import React from "react";
import { Grid } from "@mui/material";

import { Page } from "../../Page";
import { NoteSection } from "../../NoteSection";
import { TestFailedComponent } from "../../TestInstance/TesterResults/TestFailed";
import { Container, StyledTypography, StyledDivider } from "./styles";
import { TesterResult, TestProtocolFragment } from "../../../generated/api-client";
import { FooterContainer } from "../style";
import { TabEnum } from "../enum";
import { FooterNavButton } from "../TabFooter";
import { TestWorkingComponent } from "../../TestInstance/TesterResults/TestWorking/TestWorking";
import InstanceLabel from "../../TestInstance/Label/InstanceLabel";

interface IFooter {
  handleTabChange: (event: React.SyntheticEvent, newValue: TabEnum) => void;
}

const Footer = ({ handleTabChange }: IFooter) => {
  return (
    <FooterContainer>
      <FooterNavButton direction="back" target={TabEnum.DATA} {...{ handleTabChange }} />
      <FooterNavButton direction="forward" target={TabEnum.REPORT} {...{ handleTabChange }} />
    </FooterContainer>
  );
};

export enum NoteType {
  Sales = "noteSales",
  FollowUp = "noteFollowUp",
  Client = "noteClient",
  Actions = "noteActions",
}

interface INotes {
  handleTabChange: (event: React.SyntheticEvent, newValue: TabEnum) => void;
  protocol: TestProtocolFragment | undefined;
}

export const Notes = ({ handleTabChange, protocol }: INotes) => {
  const objectsWithNotes =
    protocol?.instances.filter(
      (x) => x.resultTester?.state === TesterResult.NotWorking || x.resultTester?.comment?.length,
    ) ?? [];
  const testSubmitted = !!protocol?.submittedOn;

  return (
    <Page title="Anteckningar" footer={<Footer handleTabChange={handleTabChange} />}>
      <Container>
        <StyledTypography variant="caption">För Consilium</StyledTypography>
        <NoteSection
          noteType={NoteType.Sales}
          header="Anteckning till säljteamet"
          note="Denna anteckning kommer endast vara läsbar för Consilium säljteam"
          value={protocol?.noteSales || ""}
          disabled={testSubmitted}
        />
        <NoteSection
          noteType={NoteType.FollowUp}
          header="Anteckning till testare"
          note="Denna anteckning är endast läsbar för dig och dina kollegor på Consilium"
          value={protocol?.noteFollowUp ?? ""}
          disabled={testSubmitted}
        />
        <StyledDivider />
        <StyledTypography variant="caption">Anmärkningar från protokollet</StyledTypography>
        {objectsWithNotes.map((instance) => (
          <Grid key={instance.id} sx={{ mb: 4 }}>
            <InstanceLabel instance={instance} />
            {instance.resultTester?.state === TesterResult.Working && (
              <TestWorkingComponent instance={instance} disabled={false} />
            )}
            {instance.resultTester?.state === TesterResult.NotWorking && (
              <TestFailedComponent instance={instance} disabled={false} />
            )}
          </Grid>
        ))}
      </Container>
    </Page>
  );
};
