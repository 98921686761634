import React from "react";
import { useRouter } from "next/router";
import { Grid, Typography } from "@mui/material";

import { StyledButton, UserContainer, Container } from "./styles";
import { Home } from "@mui/icons-material";

export interface HeaderProps {
  title: string | React.ReactNode;
  hideOverviewButton?: boolean;
}

export const HeaderComponent = ({ title, hideOverviewButton }: HeaderProps) => {
  const router = useRouter();
  const gotToOverviewPage = () => router.push("/");

  return (
    <Container sx={{ gridArea: "header" }}>
      <Grid>
        {typeof title === "string" ? <Typography variant="h4">{title}</Typography> : title}
      </Grid>
      <UserContainer>
        {!hideOverviewButton && (
          <StyledButton variant="contained" onClick={gotToOverviewPage}>
            <Home />
            &nbsp; Översikt
          </StyledButton>
        )}
      </UserContainer>
    </Container>
  );
};
