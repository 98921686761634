import { Grid, FormControlLabel, Radio } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  flex: 1,
  maxHeight: "100%",
  marginLeft: 20,
  paddingTop: 20,
}));

export const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  width: 160,
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  "& svg": {
    width: "1.2em",
    height: "1.2em",
  },
}));

export const RadioLabelContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));
