import React from "react";
import { useRouter } from "next/router";

import { TextFieldComponent } from "../TextField";
import { Container, StyledHeader, StyledNote } from "./styles";
import { useSubmitNotesToTestRunMutation } from "../../generated/api-client";
import { ErrorAlert } from "../ErrorAlert";

interface INoteSection {
  noteType: string;
  header: string;
  note: string;
  value: string;
  disabled: boolean;
}

export const NoteSection = ({ noteType, header, note, value, disabled }: INoteSection) => {
  const [{ fetching: loading, error }, submitNotes] = useSubmitNotesToTestRunMutation();
  const router = useRouter();
  const { testId } = router.query;

  const submitNoteAction = async (text: string) => {
    await submitNotes({
      testRun: testId as string,
      [noteType]: text,
    });
  };

  return (
    <Container>
      {error && <ErrorAlert message={error.message || "Något gick fel"} />}
      <StyledHeader variant="subtitle1">{header}:</StyledHeader>
      <StyledNote variant="body2">{note}</StyledNote>
      <TextFieldComponent
        disabled={disabled}
        submitMutationAction={submitNoteAction}
        error={error}
        loading={loading}
        initialValue={value}
        textFieldValue={value}
        multiline
      />
    </Container>
  );
};
