import React, { useState } from "react";

import {
  Container,
  ContentContainer,
  StyledFormGroup,
  StyledFormControl,
  StyledRadio,
} from "./styles";
import {
  TesterResult,
  TestInstanceFragment,
  useSubmitTestResultMutation,
} from "../../../../generated/api-client";
import { TextFieldComponent } from "../../../TextField";
import { Photos } from "../../Photos/Photos";

export enum ObjectStatus {
  DoesntExist = "Finns ej",
  CantFindIt = "Kan inte hitta",
  Inaccessible = "Inte nåbar",
  Other = "Annat",
}

interface ISkipped {
  instance: TestInstanceFragment;
  disabled: boolean;
}

export const Skipped = ({ instance, disabled }: ISkipped) => {
  const [objectStatus, setObjectStatus] = useState<ObjectStatus | undefined>(undefined);
  const [{ fetching: loading, error }, submitTest] = useSubmitTestResultMutation();
  const comment = instance.resultTester?.comment?.trim();

  const isOtherField =
    objectStatus === ObjectStatus.Other ||
    !comment ||
    !Object.values<string>(ObjectStatus).includes(comment);

  const handleSelected = (status: ObjectStatus) => {
    setObjectStatus(status);
    submitTestAction(status);
  };

  const submitTestAction = (text: string) => {
    submitTest({
      result: TesterResult.Skipped,
      testInstance: instance.id,
      comment: text,
    });
  };

  const inputDisabled = disabled || loading;

  return (
    <Container>
      <ContentContainer>
        <StyledFormGroup>
          <StyledFormControl
            disabled={inputDisabled}
            control={
              <StyledRadio
                checked={(objectStatus || comment) === ObjectStatus.DoesntExist}
                onChange={() => handleSelected(ObjectStatus.DoesntExist)}
              />
            }
            label={ObjectStatus.DoesntExist}
          />
          <StyledFormControl
            disabled={inputDisabled}
            control={
              <StyledRadio
                checked={(objectStatus || comment) === ObjectStatus.CantFindIt}
                onChange={() => handleSelected(ObjectStatus.CantFindIt)}
              />
            }
            label={ObjectStatus.CantFindIt}
          />
          <StyledFormControl
            disabled={inputDisabled}
            control={
              <StyledRadio
                checked={(objectStatus || comment) === ObjectStatus.Inaccessible}
                onChange={() => handleSelected(ObjectStatus.Inaccessible)}
              />
            }
            label={ObjectStatus.Inaccessible}
          />
          <StyledFormControl
            disabled={inputDisabled}
            control={
              <StyledRadio
                checked={(objectStatus || comment) === ObjectStatus.Other || isOtherField}
                onChange={() => handleSelected(ObjectStatus.Other)}
              />
            }
            label={ObjectStatus.Other}
          />
        </StyledFormGroup>
      </ContentContainer>
      {isOtherField && (
        <TextFieldComponent
          disabled={inputDisabled}
          submitMutationAction={submitTestAction}
          error={error}
          loading={loading}
          initialValue={instance.resultTester?.comment ?? ""}
          textFieldValue={objectStatus}
        />
      )}
      <Photos resultTester={instance.resultTester} disabled={disabled} />
    </Container>
  );
};
