import { Grid, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const GridDivider = styled(Divider)(({ theme }) => ({
  marginTop: 10,
}));

export const ChartContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.grey[200],
  borderRadius: 5,
}));

export const PercentageWorking = styled(Typography)(({ theme }) => ({
  color: "#00579d",
  paddingRight: 5,
  width: 40,
}));

export const PercentageNotWorking = styled(Typography)(({ theme }) => ({
  color: "#00579d",
  paddingRight: 5,
  width: 40,
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
  minHeight: "100%",
  marginLeft: 20,
}));

export const DoughnutContainer = styled(Grid)(({ theme }) => ({
  height: 90,
  width: 90,
}));
