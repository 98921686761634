import React, { useState } from "react";
import { Collapse, IconButton, Snackbar } from "@mui/material";

import { StyledErrorAlert, StyledErrorIcon } from "./styles";
import { Close as CloseIcon } from "@mui/icons-material";

interface IErrorAlert {
  message: string;
  duration?: number;
}

export const ErrorAlert = ({ message, duration = 0 }: IErrorAlert) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Collapse in={open}>
      <StyledErrorAlert
        onClose={handleClose}
        icon={<StyledErrorIcon />}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
        severity="error"
      >
        {message}
      </StyledErrorAlert>
    </Collapse>
  );
};

export default ErrorAlert;
