import { Alert } from "@mui/material";
import styled from "@emotion/styled";
import { Error } from "@mui/icons-material";

export const StyledErrorAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  width: "100%",
}));

export const StyledErrorIcon = styled(Error)(({ theme }) => ({
  color: theme.palette.common.white,
}));
