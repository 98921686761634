import type { NextPage } from "next";
import Head from "next/head";
import { Grid } from "@mui/material";
import { ContentContainer, FooterContainer } from "./styles";

export interface PageProps {
  title: string;
  description?: string;
  footer?: React.ReactNode;
}

const Page: NextPage<PageProps> = ({ title, description, children, footer }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description ?? title} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Grid
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <ContentContainer>{children}</ContentContainer>
        {footer && (
          <FooterContainer sx={{ borderTop: 1, borderColor: "#f6f6f6" }}>{footer}</FooterContainer>
        )}
      </Grid>
    </>
  );
};

export default Page;
