import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const Outer = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",

  backgroundColor: theme.palette.grey[200],
  borderRadius: 20,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.grey[200],

  "& + span": {
    marginLeft: 6,
  },
}));

const FieldName = styled("span")(({ theme }) => ({
  display: "inline-block",
  padding: "0 4px",
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[700],
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
}));

const Value = styled("span")(({ theme }) => ({
  display: "inline-block",
  padding: "0 4px",
  color: theme.palette.grey[800],
  backgroundColor: "white",
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
}));

export interface BadgeProps {
  field: string;
  value: any;
}

export const Badge = ({ field, value }: BadgeProps) => {
  if (value === undefined) return null;
  if (value === null) return null;

  return (
    <Outer variant="caption">
      <FieldName>{field}</FieldName>
      <Value>{value}</Value>
    </Outer>
  );
};
