import { Grid, Typography, Button, TextField } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

export const SubmittedContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 250,
}));

export const CaptionText = styled(Typography)(({ theme }) => ({
  color: "#FC6C43",
  paddingBottom: 10,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  paddingTop: 10,
  paddingBottom: 10,
}));

export const StyledReportButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  flex: 1,
  marginBottom: 10,
  color: theme.palette.common.white,
  marginLeft: 10,
}));

export const StyledPreviewButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  flex: 1,
  marginBottom: 10,
  backgroundColor: theme.palette.grey[200],
  color: "#5A5A5A",
  marginRight: 10,
}));

export const StyledTextField = styled(TextField)(() => ({
  paddingTop: 5,
  borderRadius: 20,
  "& fieldset": {
    borderRadius: 20,
  },
}));
