import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  CheckBox as TickedCheckBoxIcon,
  CheckBoxOutlineBlank as UntickedCheckBoxIcon,
  ErrorOutline as ErrorIcon,
  Warning as WarningIcon,
  ArrowCircleUp as ArrowUpIcon,
} from "@mui/icons-material";

import { StyledTableRow, StyledTableCell } from "./styles";
import { TranslateTestFailedStatus } from "../../../../../utils/sectionName";
import { TestFailedStatus, TestInstanceFragment } from "../../../../../generated/api-client";

interface ITableSection {
  instances: TestInstanceFragment[];
  sectionName: string;
}

const objectId = (object: TestInstanceFragment) =>
  [object.object?.zone, object.object?.physicalAddress, object.id].filter((x) => x).join("/");

export const TableSection = ({ instances }: ITableSection) => {
  return (
    <TableContainer component={Paper} style={{ marginBottom: 10, boxShadow: "none" }}>
      <Table sx={{ width: "100%" }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Nivå</TableCell>
            <TableCell>Zon/ID</TableCell>
            <TableCell>Objekt</TableCell>
            <TableCell>Problem</TableCell>
            <TableCell>Åtgärd</TableCell>
            <TableCell>Fixad?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {instances.map((item: TestInstanceFragment, index: number) => (
            <React.Fragment key={item.id}>
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Grid item display="flex">
                    {item.resultTester?.testFailedStatus === TestFailedStatus.Warning && (
                      <ErrorIcon style={{ color: "#ffe562", marginRight: 3 }} />
                    )}
                    {item.resultTester?.testFailedStatus === TestFailedStatus.Critical && (
                      <WarningIcon style={{ color: "#ff8b60", marginRight: 3 }} />
                    )}
                    {item.resultTester?.testFailedStatus === TestFailedStatus.Improvement && (
                      <ArrowUpIcon style={{ color: "#00579d", marginRight: 3 }} />
                    )}
                    <Typography paddingLeft={1}>
                      {
                        TranslateTestFailedStatus[
                          item.resultTester?.testFailedStatus as TestFailedStatus
                        ]
                      }
                    </Typography>
                  </Grid>
                </StyledTableCell>

                <StyledTableCell>{objectId(item)}</StyledTableCell>
                <StyledTableCell align="left">{item.name}</StyledTableCell>
                <StyledTableCell>{item.resultTester?.comment}</StyledTableCell>
                <StyledTableCell>{item?.resultTester?.recommendation || ""}</StyledTableCell>
                <StyledTableCell style={{ display: " flex", alignItems: "center" }}>
                  {item.resultTester?.actionTakenByTester ? (
                    <TickedCheckBoxIcon style={{ color: "#8F9497" }} />
                  ) : (
                    <UntickedCheckBoxIcon style={{ color: "#8F9497" }} />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
