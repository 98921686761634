import React, { useState, useEffect } from "react";
import { CircularProgress, InputAdornment } from "@mui/material";
import { CombinedError } from "urql";

import { StyledTextField } from "./styles";

interface ITextFieldComponent {
  textFieldValue?: string;
  submitMutationAction: (text: string) => void;
  error?: CombinedError;
  loading: boolean;
  initialValue: string;
  multiline?: boolean;
  disabled?: boolean;
  label?: string;
}

export const TextFieldComponent = ({
  textFieldValue,
  submitMutationAction,
  error,
  loading,
  initialValue,
  multiline,
  disabled,
  label,
}: ITextFieldComponent) => {
  const [fieldValue, setFieldValue] = useState(initialValue ?? "");
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue(event.target.value);

  useEffect(() => {
    if (textFieldValue) setFieldValue(textFieldValue);
  }, [textFieldValue]);

  return (
    <StyledTextField
      variant="outlined"
      size="small"
      fullWidth
      error={error ? true : false}
      disabled={loading || disabled}
      value={fieldValue}
      onChange={handleTextFieldChange}
      onBlur={() => submitMutationAction(fieldValue)}
      helperText={error?.message}
      label={label}
      {...(multiline && { multiline: true, minRows: 3 })}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress size={15} variant={loading ? "indeterminate" : "determinate"} />
          </InputAdornment>
        ),
      }}
    />
  );
};
