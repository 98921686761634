import React, { ChangeEvent } from "react";
import { LoadingButton } from "@mui/lab";
import { PhotoLibrary } from "@mui/icons-material";

interface UploadPhotoProps {
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  disabled: boolean;
}

export const UploadPhoto = ({ onChange, loading, disabled }: UploadPhotoProps) => {
  return (
    <label>
      <input
        type="file"
        accept="image/*;capture=camera"
        id="upload-photo"
        style={{ display: "none" }}
        required
        onChange={onChange}
      />
      <LoadingButton
        disabled={disabled}
        color="secondary"
        variant="contained"
        startIcon={<PhotoLibrary />}
        component="span"
        sx={{
          borderRadius: 20,
          color: "#384045",
        }}
        loading={loading}
      >
        Bifoga bild
      </LoadingButton>
    </label>
  );
};
