import React from "react";
import { Grid, Checkbox, FormGroup, FormControlLabel, Typography, Divider } from "@mui/material";

import { Container, RadioLabelContainer, StyledRadio } from "./styles";
import {
  TesterResult,
  TestInstanceFragment,
  useSubmitTestResultMutation,
  TestFailedStatus,
} from "../../../../generated/api-client";
import { TextFieldComponent } from "../../../TextField";
import { ErrorAlert } from "../../..";
import { Photos } from "../../Photos/Photos";

const translateStatus = (x: TestFailedStatus): string => {
  switch (x) {
    case TestFailedStatus.Critical:
      return "Kritiskt";
    case TestFailedStatus.Improvement:
      return "Förbättring";
    case TestFailedStatus.Warning:
      return "Varning";
  }
};

interface ITestFailedComponent {
  instance: TestInstanceFragment;
  disabled: boolean;
}

export const TestWorkingComponent = ({ instance, disabled }: ITestFailedComponent) => {
  const [{ fetching: loading, error }, submitTest] = useSubmitTestResultMutation();

  const submitCommentAction = (text: string) => {
    submitTest({
      result: TesterResult.Working,
      testInstance: instance.id,
      comment: text,
    });
  };

  const submitIncludeInReport = (includeInReport: boolean) => {
    submitTest({
      result: TesterResult.Working,
      testInstance: instance.id,
      includeInReport,
    });
  };

  const IncludeInReport = (
    <Grid>
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={instance.resultTester?.includeInReport}
            onChange={() => submitIncludeInReport(true)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>Inkludera i rapport</Typography>
          </RadioLabelContainer>
        }
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={!instance.resultTester?.includeInReport}
            onChange={() => submitIncludeInReport(false)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>
              Inkludera <strong>inte</strong> i rapport
            </Typography>
          </RadioLabelContainer>
        }
      />
    </Grid>
  );

  return (
    <Container>
      {error && <ErrorAlert message={error.message || "Något gick fel"} />}
      <Grid sx={{ margin: "12px 0" }}>
        <TextFieldComponent
          label="Kommentar"
          disabled={disabled}
          submitMutationAction={submitCommentAction}
          error={error}
          loading={loading}
          initialValue={instance.resultTester?.comment ?? ""}
        />
      </Grid>
      <Divider sx={{ mt: 1, mb: 1 }} />
      {IncludeInReport}
      <Photos resultTester={instance.resultTester} disabled={disabled} />
    </Container>
  );
};
