import { Button, Grid } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: 20,
}));

export const UserContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "space-between",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  "@media print": {
    visibility: "hidden",
  },
  borderRadius: 20,
  width: 150,
  marginBottom: 10,
  color: theme.palette.common.white,
}));
