import React, { ChangeEvent, useState } from "react";

import { Box } from "@mui/system";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import { Close as CloseIcon, Delete as DeleteIcon } from "@mui/icons-material";

import {
  PhotoFragment,
  ResultTesterFragment,
  useDeletePhotoMutation,
  useUploadPhotoMutation,
} from "../../../generated/api-client";

import { UploadPhoto } from "./UploadPhoto";
import { ErrorAlert } from "../..";

interface PhotoModalProps {
  photo: PhotoFragment;
}

export function PhotoModal({ photo }: PhotoModalProps) {
  const [open, setOpen] = useState(false);
  const [{ error, fetching: loading }, submitDeletePhoto] = useDeletePhotoMutation();

  const deletePhoto = () => {
    submitDeletePhoto({ photoId: photo.id }).then(() => setOpen(false));
  };

  return (
    <Card sx={{ marginLeft: 1 }}>
      <CardActionArea onClick={() => setOpen(true)}>
        <CardMedia component="img" alt={photo.filename} src={photo.thumbnailUrl} height={50} />
      </CardActionArea>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Card
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "200px",
            maxWidth: "80vw",
            minHeight: "200px",
            maxHeight: "80vw",
            backgroundColor: "background.paper",
            overflow: "auto",
          }}
        >
          <CardHeader
            title={<code>{photo.filename}</code>}
            action={
              <IconButton aria-label="close" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardMedia component="img" image={photo.fileUrl} alt={photo.filename} />
          {error && <ErrorAlert message={error.message || "Något gick fel"} />}
          <CardActions>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={deletePhoto}
              disabled={loading}
            >
              Radera foto
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Card>
  );
}

export interface PhotosProps {
  resultTester?: ResultTesterFragment | null;
  disabled: boolean;
}

export function Photos({ resultTester, disabled }: PhotosProps) {
  const [{ fetching: loading, error }, uploadPhoto] = useUploadPhotoMutation();

  if (!resultTester) {
    return <></>;
  }

  const uploadPhotoAction = async ({
    target: { validity, files },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid) {
      throw new Error(`Cannot upload photo: ${validity}`);
    }
    if (!files || files.length == 0) {
      throw new Error(`Cannot upload photo: No photo`);
    }

    await uploadPhoto({
      testResultTester: resultTester.id,
      file: files[0],
    });
  };

  return (
    <Grid paddingTop={1} paddingBottom={1}>
      {error && <ErrorAlert message={error.message || "Något gick fel"} />}
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <UploadPhoto onChange={uploadPhotoAction} loading={loading} disabled={disabled} />
        <Box sx={{ marginLeft: 1, display: "flex" }}>
          {(resultTester?.photos || []).map((photo) => (
            <PhotoModal key={photo.id} photo={photo} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
