import React, { ChangeEvent } from "react";
import { LoadingButton } from "@mui/lab";
import { Usb as UsbIcon, Upload as UploadIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

interface UploadSyslogProps {
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

export const UploadSyslog = ({ onChange, loading }: UploadSyslogProps) => {
  const theme = useTheme();

  return (
    <>
      <input
        id="upload-sys-log"
        type="file"
        style={{ display: "none" }}
        required
        onChange={onChange}
      />
      <label htmlFor="upload-sys-log">
        <LoadingButton
          variant="contained"
          component="span"
          startIcon={<UploadIcon />}
          sx={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 20,
          }}
          loading={loading}
        >
          Ladda upp data
        </LoadingButton>
      </label>
    </>
  );
};
