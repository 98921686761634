import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  display: "flex",
  alignItems: "flex-end",
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  paddingLeft: 20,
}));

export const TermsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
