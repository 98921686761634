import React, { useState } from "react";
import {
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  VerifiedUser as VerifiedIcon,
  DeleteForever as DeleteIcon,
} from "@mui/icons-material";
import {
  Typography,
  CircularProgress,
  Tooltip,
  Button,
  Grid,
  Snackbar,
  IconButton,
  Box,
  Alert as MuiAlert,
  AlertProps,
} from "@mui/material";

import {
  Container,
  ButtonLikeBox,
  ThumbsUpButton,
  ThumbsDownButton,
  SkipButton,
  ButtonsContainer,
  TestInstanceContainer,
} from "./styles";
import { TestFailedComponent, Skipped, ObjectStatus } from "./TesterResults";
import {
  TesterResult,
  useSubmitTestResultMutation,
  TestInstanceFragment,
  useDeleteTestMutation,
  useUnDeleteTestMutation,
  TestInstanceGroup,
} from "../../generated/api-client";
import { ErrorAlert } from "../ErrorAlert";
import { TestWorkingComponent } from "./TesterResults/TestWorking/TestWorking";
import InstanceLabel from "./Label/InstanceLabel";

const SmallLoadingIndicator = () => <CircularProgress size={24} />;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeletedTestInstance = ({ instance }: { instance: TestInstanceFragment }) => {
  const [{ error }, undeleteTest] = useUnDeleteTestMutation();
  const [errorOpen, setErrorOpen] = useState(false);
  const trigger = () => {
    undeleteTest({ testRunInstance: instance.id })
      .then((x) => setErrorOpen(!!x.error))
      .catch(() => setErrorOpen(true));
  };

  return (
    <TestInstanceContainer sx={{ display: "flex" }}>
      <Box sx={{ flex: 1, opacity: 0.3, pointerEvents: "none" }}>
        <InstanceLabel big instance={instance} />
      </Box>
      <Button onClick={trigger}>Inkludera igen</Button>

      <Snackbar open={errorOpen} autoHideDuration={5000}>
        <Alert severity="error">{error?.message || "Något gick fel"}</Alert>
      </Snackbar>
    </TestInstanceContainer>
  );
};

const DeleteButton = ({ instance }: { instance: TestInstanceFragment }) => {
  const [{ error }, deleteTest] = useDeleteTestMutation();
  const [errorOpen, setErrorOpen] = useState(false);
  const trigger = () => {
    deleteTest({ testRunInstance: instance.id })
      .then((x) => setErrorOpen(!!x.error))
      .catch(() => setErrorOpen(true));
  };

  return (
    <>
      <IconButton sx={{ ml: 1 }} onClick={trigger}>
        <DeleteIcon />
      </IconButton>
      <Snackbar open={errorOpen} autoHideDuration={5000}>
        <Alert severity="error">{error?.message || "Något gick fel"}</Alert>
      </Snackbar>
    </>
  );
};

interface ITestableObject {
  instance: TestInstanceFragment;
  disabled: boolean;
}

export const TestInstance = ({ instance, disabled }: ITestableObject) => {
  const [loadingButton, setLoadingButton] = useState<TesterResult | null>(null);
  const [{ fetching: loading, error, data }, submitTest] = useSubmitTestResultMutation();

  if (instance.deletedAt) {
    return <DeletedTestInstance instance={instance} />;
  }

  const state = instance.resultTester?.state;
  const tested = state === TesterResult.Working || state === TesterResult.NotWorking;

  const submitTestAction = (testerResult: TesterResult) => {
    setLoadingButton(testerResult);
    const currentComment = instance.resultTester?.comment;
    const hasComment = currentComment?.length;

    // set comment...
    let comment = null;
    if (testerResult === TesterResult.Skipped && !hasComment) {
      // ...to default skip reason if we pressed "skip" initially
      comment = ObjectStatus.DoesntExist;
    } else if (
      testerResult !== TesterResult.Skipped &&
      hasComment &&
      Object.values<string>(ObjectStatus).includes(currentComment)
    ) {
      // to nothing if we switched from a predefined skip reason to a non-skip state
      comment = "";
    }
    submitTest({
      result: testerResult,
      testInstance: instance.id,
      ...(comment ? { comment } : {}),
    }).finally(() => setLoadingButton(null));
  };

  const disableButtons = disabled || loading;

  if ([TestInstanceGroup.PreTest, TestInstanceGroup.PostTest].includes(instance.group)) {
    return (
      <TestInstanceContainer>
        <Container>
          <InstanceLabel big instance={instance} />
          <ThumbsUpButton
            color="secondary"
            disabled={disableButtons || state === TesterResult.Working}
            value="check"
            selected={state === TesterResult.Working}
            onClick={() => submitTestAction(TesterResult.Working)}
          >
            {loadingButton == TesterResult.Working ? <SmallLoadingIndicator /> : <ThumbUpIcon />}
            <Typography sx={{ pl: 1 }}>Godkänd</Typography>
          </ThumbsUpButton>
        </Container>
      </TestInstanceContainer>
    );
  }

  return (
    <TestInstanceContainer>
      <Container>
        {error && <ErrorAlert message={error.message || "Något gick fel"} />}
        <InstanceLabel big instance={instance} />
        <ButtonsContainer>
          {instance.resultSystem && (
            <Tooltip title="Verifierad av Consilium System" arrow>
              <ButtonLikeBox>
                <VerifiedIcon color="info" />
              </ButtonLikeBox>
            </Tooltip>
          )}
          <SkipButton
            color="secondary"
            disabled={disableButtons || tested}
            value="check"
            selected={tested}
            onClick={() => submitTestAction(TesterResult.Working)}
          >
            {loadingButton === TesterResult.Working || loadingButton === TesterResult.NotWorking ? (
              <SmallLoadingIndicator />
            ) : (
              <Typography>Testad</Typography>
            )}
          </SkipButton>
          <SkipButton
            color="secondary"
            disabled={disableButtons || state === TesterResult.Skipped}
            value="check"
            selected={state === TesterResult.Skipped}
            onClick={() => submitTestAction(TesterResult.Skipped)}
            sx={{ ml: 1 }}
          >
            {loadingButton === TesterResult.Skipped ? (
              <SmallLoadingIndicator />
            ) : (
              <Typography>Inte testad</Typography>
            )}
          </SkipButton>
          <DeleteButton instance={instance} />
        </ButtonsContainer>
      </Container>
      {tested && (
        <Grid sx={{ mt: 2, pl: 2, pr: 2 }}>
          <ThumbsUpButton
            color="secondary"
            disabled={disableButtons || state === TesterResult.Working}
            value="check"
            selected={state === TesterResult.Working}
            onClick={() => submitTestAction(TesterResult.Working)}
          >
            {loadingButton == TesterResult.Working ? <SmallLoadingIndicator /> : <ThumbUpIcon />}
            <Typography sx={{ pl: 1 }}>Godkänd</Typography>
          </ThumbsUpButton>
          <ThumbsDownButton
            color="secondary"
            disabled={disableButtons || state === TesterResult.NotWorking}
            value="check"
            selected={state === TesterResult.NotWorking}
            onClick={() => submitTestAction(TesterResult.NotWorking)}
          >
            {loadingButton == TesterResult.NotWorking ? (
              <SmallLoadingIndicator />
            ) : (
              <ThumbDownIcon />
            )}
            <Typography sx={{ pl: 1 }}>Ej Godkänd</Typography>
          </ThumbsDownButton>
        </Grid>
      )}
      {state === TesterResult.Working && (
        <TestWorkingComponent instance={instance} disabled={disabled} />
      )}
      {state === TesterResult.NotWorking && (
        <TestFailedComponent instance={instance} disabled={disabled} />
      )}
      {state === TesterResult.Skipped && <Skipped instance={instance} disabled={disabled} />}
    </TestInstanceContainer>
  );
};
