import { Grid, Button } from "@mui/material";
import styled from "@emotion/styled";

export const LoadingContainer = styled(Grid)(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const FooterContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flex: 1,
  padding: 10,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  width: 250,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
}));
