import React from "react";
import { Typography } from "@mui/material";

import { Container, StyledText, TermsText } from "./styles";

export const FooterComponent = () => {
  return (
    <Container
      sx={{
        gridArea: "footer",
        "@media print": {
          visibility: "hidden",
        },
      }}
    >
      <Typography variant="subtitle2">Consilium</Typography>
      <StyledText variant="caption">
        &copy; Diamond Service Protokoll 2021. Alla rättigheter reserverade. Se{" "}
        <TermsText variant="caption">Villkor</TermsText>
      </StyledText>
    </Container>
  );
};
