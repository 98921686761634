import { Accordion, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: 10,
}));

export const SummaryContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
}));

export const LeftContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const InfoContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: 20,
}));

export const StylesProgressText = styled(Typography)(({ theme }) => ({
  paddingRight: 20,
}));
