import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CommentIcon from "@mui/icons-material/Comment";
import { RandomContainerInReport } from "../styles";
import { Box } from "@mui/system";

interface SummaryProps {
  generalNote: string;
  recommendation: string;
}

export const Summary = ({ generalNote, recommendation }: SummaryProps) => {
  const iconStyle = { fontSize: "36px", marginRight: 2 };

  return (
    <RandomContainerInReport>
      <Grid container direction="row">
        <Grid container direction="row" sx={{ flex: 1 }}>
          <InsertDriveFileIcon color="primary" sx={iconStyle} />
          <Grid item container direction="column" sx={{ marginRight: 1, flex: 1 }}>
            <Grid sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "400", letterSpacing: "-0.2px" }}>
                Utlåtande
              </Typography>
            </Grid>
            <Grid>
              {generalNote.split("\n\n").map((line, idx) => (
                <p key={idx}>{line}</p>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          light
          sx={{ marginRight: 3, marginLeft: 3 }}
        />
        <Grid container direction="row" sx={{ flex: 1 }}>
          <CommentIcon color="primary" sx={iconStyle} />
          <Grid item container direction="column" sx={{ marginLeft: 1, flex: 1 }}>
            <Grid sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "400", letterSpacing: "-0.2px" }}>
                Ytterligare kommentar
              </Typography>
            </Grid>
            <Grid>
              {recommendation.split("\n\n").map((line, idx) => (
                <p key={idx}>{line}</p>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RandomContainerInReport>
  );
};
