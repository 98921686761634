import { ArrowForward, ArrowBack } from "@mui/icons-material";
import React from "react";
import { TabEnum } from "./enum";
import { StyledButton } from "./style";

interface FooterNavButtonProps {
  handleTabChange: (event: React.SyntheticEvent, newValue: TabEnum) => void;
  target: TabEnum;
  direction: "forward" | "back";
}

export const FooterNavButton = ({ direction, handleTabChange, target }: FooterNavButtonProps) => (
  <StyledButton
    variant="outlined"
    startIcon={direction === "forward" ? <ArrowForward /> : <ArrowBack />}
    onClick={(event) => handleTabChange(event, target)}
    sx={{ borderRadius: 20, width: 300, color: "#5D5E60", borderColor: "#5D5E60" }}
  >
    {target}
  </StyledButton>
);
