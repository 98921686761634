import {
  ObjectFragment,
  TestableObjectType,
  TestFailedStatus,
  TestInstanceGroup,
} from "../generated/api-client";

export const SortTestInstanceGroup: Record<TestInstanceGroup, number> = {
  PreTest: 1,
  Central: 2,
  ControlFunctions: 3,
  Detectors: 4,
  IndividualDetectors: 5,
  TestedBySystem: 5,
  Documentation: 6,
  PostTest: 7,
};

export const TestInstanceGroupInReport: Record<TestInstanceGroup, boolean> = {
  PreTest: false,
  Central: true,
  ControlFunctions: true,
  Detectors: true,
  IndividualDetectors: true,
  TestedBySystem: true,
  Documentation: true,
  PostTest: false,
};

export const TranslateTestInstanceGroup: Record<TestInstanceGroup, string> = {
  PreTest: "Förberedelse",
  PostTest: "Avslutning",
  Central: "Centralapparat/BFT",
  ControlFunctions: "Styrningar",
  Detectors: "Sektion/Detektorer",
  Documentation: "Dokumentation",
  IndividualDetectors: "Detektorer (testas av systemet)",
  TestedBySystem: "Testas av systemet",
};

export const TranslateTestFailedStatus: Record<TestFailedStatus, string> = {
  Critical: "Kritisk",
  Improvement: "Förbättring",
  Warning: "Varning",
};

export class SectionName {
  private readonly sectionName: string;
  private readonly zone?: number;

  constructor(object: ObjectFragment) {
    if (object.type === TestableObjectType.Documentation) {
      this.sectionName = "Dokumentation";
    } else if (object.zone != null) {
      this.sectionName = `Zon ${object.zone}`;
      this.zone = object.zone;
    } else {
      this.sectionName = "Utgångar";
    }
  }

  toString() {
    return this.sectionName;
  }

  compare(other: SectionName) {
    if (!this.zone || !other.zone) return this.sectionName.localeCompare(other.sectionName);
    else return this.zone - other.zone;
  }
}

export type WithSectionName<T> = T & { sectionName: SectionName };
TestFailedStatus;
