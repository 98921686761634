import React from "react";
import { Typography } from "@mui/material";
import { ObjectFragment } from "../../../generated/api-client";
import { HeaderContainer } from "../styles";
import ObjectLabel from "./ObjectLabel";

type Props = {
  instance: { name: string; object?: ObjectFragment | null };
  big?: boolean;
};

export default function InstanceLabel({ instance: { name, object }, big }: Props) {
  return (
    <HeaderContainer sx={{ flexWrap: "nowrap" }}>
      {!object && (
        <Typography variant={big ? "h6" : "subtitle1"} padding="0 10px" sx={{ minWidth: 300 }}>
          {name}
        </Typography>
      )}
      {object && <ObjectLabel big={big} object={object} />}
    </HeaderContainer>
  );
}
