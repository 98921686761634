import React, { useState, useEffect } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import {
  CheckCircle as CheckIcon,
  Warning as WarningIcon,
  VerifiedUser as VerifiedIcon,
  CheckCircleOutline as OkayIcon,
  Error as NotOkayIcon,
} from "@mui/icons-material";

import { Chart } from "./components";
import {
  SummaryContainer,
  ContentContainer,
  VerifiedContainer,
  StyledAccordion as Accordion,
} from "./styles";
import {
  TesterResult,
  TestFailedStatus,
  TestInstanceFragment,
} from "../../../generated/api-client";

interface ITestCard {
  sectionName: string;
  instances: TestInstanceFragment[];
  siteId: string;
}

export const TestCard = ({ sectionName, instances, siteId }: ITestCard) => {
  const [expanded, setExpanded] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const toggleExpandAction = () => setExpanded(!expanded);

  const testedObjects = instances.filter(
    (instance) =>
      instance.resultSystem ||
      (instance.resultTester && instance.resultTester.state !== TesterResult.Skipped),
  );
  const notWorking = testedObjects.filter(
    (instance) => instance.resultTester && instance.resultTester.state === TesterResult.NotWorking,
  );

  useEffect(() => {
    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    return () => {
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
    };
  });

  const beforePrint = () => setIsPrinting(true);
  const afterPrint = () => setIsPrinting(false);

  const RenderIcon = () => {
    if (notWorking.length === 0) return <CheckIcon sx={{ fontSize: "36px", color: "#348C51" }} />;

    const x = notWorking.filter(
      (item) => item.resultTester?.testFailedStatus === TestFailedStatus.Critical,
    );
    if (x.length > 0) return <WarningIcon sx={{ fontSize: "36px", color: "#FC643A" }} />;

    return <CheckIcon sx={{ fontSize: "36px", color: "#ffe562" }} />;
  };

  return (
    <Accordion disableGutters expanded={isPrinting || expanded}>
      <AccordionSummary expandIcon={<ExpandMore />} onClick={toggleExpandAction}>
        <SummaryContainer>
          <ContentContainer item xs={6} sx={{ flexDirection: "row" }}>
            <RenderIcon />
            <Typography
              sx={{ fontSize: "24px", fontWeight: "700", letterSpacing: "-0.2px", pl: 2 }}
            >
              {sectionName}
            </Typography>
          </ContentContainer>
          <ContentContainer
            item
            xs={2}
            sx={{ flexDirection: "row", borderRight: 1, borderColor: "blue" }}
          >
            <Typography variant="h3" sx={{ color: "#409b64" }}>
              {testedObjects.length}
            </Typography>
            <Typography sx={{ marginLeft: 1, maxWidth: 90, paddingLeft: 1 }} variant="subtitle2">
              Objekt testade
            </Typography>
          </ContentContainer>
          <ContentContainer
            item
            xs={4}
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              borderRight: 1,
              paddingRight: 2,
              marginRight: 1,
            }}
          >
            <Typography variant="h3" sx={{ color: "#00579d" }}>
              {notWorking.length}
            </Typography>
            <Typography sx={{ marginLeft: 1, paddingLeft: 1 }} variant="subtitle2">
              Problem identifierade
            </Typography>
          </ContentContainer>
        </SummaryContainer>
      </AccordionSummary>
      <AccordionDetails>
        <Chart sectionName={sectionName} instances={instances} />
        {testedObjects && testedObjects?.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ marginTop: 3, boxShadow: "none", borderRadius: "5px" }}
          >
            <VerifiedContainer sx={{ border: 1, alignItems: "center" }}>
              <VerifiedIcon sx={{ color: "#61A3D2" }} />
              <Typography variant="h6" sx={{ paddingLeft: 1, paddingRight: 2, minWidth: 350 }}>
                Verifierad av Consilium System
              </Typography>
              <Typography variant="caption">
                Dessa föremål testades av både testaren och centralpanelen, vilket säkerställde att
                de fungerar. Det betyder också att testet är spårbart och att du kan vara säker på
                att det kördes korrekt.
              </Typography>
            </VerifiedContainer>
            <Typography sx={{ fontSize: "20px", fontWeight: "500", margin: "36px 0" }}>
              Testade Objekt
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Verifierad</TableCell>
                  <TableCell>OK?</TableCell>
                  <TableCell>Tid</TableCell>
                  <TableCell>Zon/ID</TableCell>
                  <TableCell>Objekt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testedObjects.map((object) => (
                  <TableRow
                    key={object.resultTester?.id}
                    sx={{ fontVariantNumeric: "tabular-nums" }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {object.resultSystem ? (
                        <>
                          <VerifiedIcon sx={{ color: "#61A3D2", marginRight: 1 }} />S
                          {siteId.padStart(2, "0")}T{object.resultSystem.id.padStart(4, "0")}
                        </>
                      ) : (
                        <VerifiedIcon sx={{ color: "#8F9497", marginRight: 1 }} />
                      )}
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {object.resultTester && (
                        <>
                          {object.resultTester?.state == TesterResult.Working ? (
                            <OkayIcon style={{ color: "#8F9497" }} />
                          ) : (
                            <NotOkayIcon style={{ color: "#8F9497" }} />
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                      {new Date(
                        object.resultTester?.updatedAt ?? object.resultSystem?.updatedAt!,
                      ).toLocaleString("sv")}
                    </TableCell>
                    <TableCell align="left">
                      {[object.object?.zone, object.object?.physicalAddress, object.id]
                        .filter((x) => x)
                        .join("/")}
                    </TableCell>
                    <TableCell align="left">{object.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
