import React, { useState } from "react";
import { Typography, Grid, Checkbox, FormGroup, FormControlLabel, Divider } from "@mui/material";
import { Warning as WarningIcon, Error as ErrorIcon, AddAlert } from "@mui/icons-material";

import { Container, StyledFormControl, StyledRadio, RadioLabelContainer } from "./styles";
import {
  TesterResult,
  TestInstanceFragment,
  useSubmitTestResultMutation,
  TestFailedStatus,
} from "../../../../generated/api-client";
import { TextFieldComponent } from "../../../TextField";
import { ErrorAlert } from "../../..";
import { Photos } from "../../Photos/Photos";

const translateStatus = (x: TestFailedStatus): string => {
  switch (x) {
    case TestFailedStatus.Critical:
      return "Kritiskt";
    case TestFailedStatus.Improvement:
      return "Förbättring";
    case TestFailedStatus.Warning:
      return "Varning";
  }
};

interface ITestFailedComponent {
  instance: TestInstanceFragment;
  disabled: boolean;
}

export const TestFailedComponent = ({ instance, disabled }: ITestFailedComponent) => {
  const [failureState, setFailureState] = useState<TestFailedStatus | undefined>(
    TestFailedStatus.Warning,
  );
  const [{ fetching: loading, error }, submitTest] = useSubmitTestResultMutation();

  const status = instance.resultTester?.testFailedStatus;
  const handleSelected = (action: TestFailedStatus) => setFailureState(action);

  const submitCommentAction = (text: string) => {
    submitTest({
      result: TesterResult.NotWorking,
      testInstance: instance.id,
      comment: text,
    });
  };

  const submitFailureStatusAction = (status: TestFailedStatus) => {
    handleSelected(status);
    submitTest({
      result: TesterResult.NotWorking,
      testInstance: instance.id,
      testFailedStatus: status,
    });
  };

  const submitRecommendation = (text: string) => {
    submitTest({
      result: TesterResult.NotWorking,
      testInstance: instance.id,
      recommendation: text,
    });
  };

  const submitIncludeInReport = (includeInReport: boolean) => {
    submitTest({
      result: TesterResult.NotWorking,
      testInstance: instance.id,
      includeInReport,
    });
  };

  const submitActionTaken = (actionTakenByTester: boolean) => {
    submitTest({
      result: TesterResult.NotWorking,
      testInstance: instance.id,
      actionTakenByTester,
    });
  };

  const Severity = (
    <Grid>
      <StyledFormControl
        disabled={disabled}
        control={
          <StyledRadio
            checked={(failureState || status) === TestFailedStatus.Critical}
            onChange={() => submitFailureStatusAction(TestFailedStatus.Critical)}
          />
        }
        label={
          <RadioLabelContainer>
            <WarningIcon style={{ color: "orange", paddingRight: 10, fontSize: 30 }} />
            <Typography>{translateStatus(TestFailedStatus.Critical)}</Typography>
          </RadioLabelContainer>
        }
      />
      <StyledFormControl
        disabled={disabled}
        control={
          <StyledRadio
            checked={(failureState || status) === TestFailedStatus.Warning}
            onChange={() => submitFailureStatusAction(TestFailedStatus.Warning)}
          />
        }
        label={
          <RadioLabelContainer>
            <ErrorIcon style={{ color: "#FEE150", paddingRight: 10, fontSize: 30 }} />
            <Typography>{translateStatus(TestFailedStatus.Warning)}</Typography>
          </RadioLabelContainer>
        }
      />
      <StyledFormControl
        disabled={disabled}
        control={
          <StyledRadio
            checked={(failureState || status) === TestFailedStatus.Improvement}
            onChange={() => submitFailureStatusAction(TestFailedStatus.Improvement)}
          />
        }
        label={
          <RadioLabelContainer>
            <AddAlert style={{ color: "#61A3D2", paddingRight: 10, fontSize: 30 }} />
            <Typography>{translateStatus(TestFailedStatus.Improvement)}</Typography>
          </RadioLabelContainer>
        }
      />
    </Grid>
  );

  const IncludeInReport = (
    <Grid>
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={instance.resultTester?.includeInReport}
            onChange={() => submitIncludeInReport(true)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>Inkludera i rapport</Typography>
          </RadioLabelContainer>
        }
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={!instance.resultTester?.includeInReport}
            onChange={() => submitIncludeInReport(false)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>
              Inkludera <strong>inte</strong> i rapport
            </Typography>
          </RadioLabelContainer>
        }
      />
    </Grid>
  );

  const AlreadyFixed = (
    <Grid>
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={instance.resultTester?.actionTakenByTester != null}
            onChange={() => submitActionTaken(true)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>Fixad på plats</Typography>
          </RadioLabelContainer>
        }
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <StyledRadio
            checked={!instance.resultTester?.actionTakenByTester}
            onChange={() => submitActionTaken(false)}
          />
        }
        label={
          <RadioLabelContainer>
            <Typography>Bör fixas senare</Typography>
          </RadioLabelContainer>
        }
      />
    </Grid>
  );

  return (
    <Container>
      {error && <ErrorAlert message={error.message || "Något gick fel"} />}
      {Severity}
      <Grid sx={{ margin: "12px 0" }}>
        <TextFieldComponent
          label="Beskriv problemet"
          disabled={disabled}
          submitMutationAction={submitCommentAction}
          error={error}
          loading={loading}
          initialValue={instance.resultTester?.comment ?? ""}
        />
      </Grid>

      <Grid sx={{ margin: "24px 0 12px" }}>
        <TextFieldComponent
          label={'Beskriv åtgärd (t.ex. "Jag byter batterier nästa gång")'}
          disabled={disabled}
          submitMutationAction={submitRecommendation}
          error={error}
          loading={loading}
          initialValue={instance.resultTester?.recommendation ?? ""}
        />
      </Grid>
      {AlreadyFixed}
      <Divider sx={{ mt: 1, mb: 1 }} />
      {IncludeInReport}
      <Photos resultTester={instance.resultTester} disabled={disabled} />
    </Container>
  );
};
