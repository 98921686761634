import { Grid } from "@mui/material";
import styled from "@emotion/styled";

export const RandomContainerInReport = styled(Grid)(({ theme }) => ({
  marginBottom: "36px",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 30,
  paddingBottom: 30,
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0px -6px 38px 0px rgba(156, 171, 194, 0.15)",
}));
