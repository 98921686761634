import { Grid, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const ContentContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
  marginTop: 40,
}));

export const RecommendationsContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  paddingTop: 30,
  paddingBottom: 30,
  marginBottom: "36px",
  breakAfter: "always",
  pageBreakAfter: "always",
  boxShadow: "0px -6px 38px 0px rgba(156, 171, 194, 0.15)",
}));

export const Item = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  borderColor: theme.palette.grey[200],
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  padding: 10,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  "@media print": {
    visibility: "hidden",
  },
  borderRadius: 20,
  width: 250,
  color: theme.palette.common.white,
}));

export const StyledCritical = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledWarnings = styled(Typography)(({ theme }) => ({
  color: "#FFE250",
}));

export const StyledImprovements = styled(Typography)(({ theme }) => ({
  color: "#61A3D2",
}));
