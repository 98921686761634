import { FormGroup, Grid, FormControlLabel, Radio } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Grid)(({ theme }) => ({
  flex: 1,
  marginLeft: 20,
  paddingTop: 20,
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: 10,
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  width: 160,
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  "& svg": {
    width: "1.2em",
    height: "1.2em",
  },
}));
