import React from "react";
import { Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";

import {
  ContentContainer,
  GridDivider,
  ChartContainer,
  PercentageWorking,
  PercentageNotWorking,
  DoughnutContainer,
} from "./styles";
import { TableSection } from "../Table";
import { TesterResult, TestInstanceFragment } from "../../../../../generated/api-client";

interface IChart {
  sectionName: string;
  instances: TestInstanceFragment[];
}

export const Chart = ({ sectionName, instances }: IChart) => {
  const testedObjects = instances.filter(
    (instance) => instance.resultTester && instance.resultTester.state !== TesterResult.Skipped,
  );
  const failedTests = testedObjects.filter(
    (instance) => instance.resultTester?.state === TesterResult.NotWorking,
  );
  const workingTests = testedObjects.filter(
    (instance) => instance.resultTester?.state === TesterResult.Working,
  );

  const working = workingTests.length;
  const notWorking = failedTests.length;
  const total = working + notWorking;

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Grid container direction="column">
      <GridDivider flexItem orientation="horizontal" />
      <Typography sx={{ fontSize: "18px", fontWeight: "700", marginTop: "24px" }}>
        Summering
      </Typography>
      <Grid container direction="column" marginTop={3}>
        <Typography variant="caption" paddingTop={1} paddingBottom={4}>
          Se nedan sammanfattning av vad som har testats och potentiella problem (om några).
        </Typography>
      </Grid>
      <ChartContainer container border={1} direction="row" marginTop={1}>
        <Grid
          container
          item
          xs={5}
          style={{ alignItems: "center" }}
          borderRight={1}
          borderColor="#f6f6f6"
        >
          <Grid item xs={5} paddingLeft={2}>
            <DoughnutContainer>
              <Doughnut
                data={{
                  datasets: [
                    {
                      data: [testedObjects.length, instances.length - testedObjects.length],
                      backgroundColor: ["#409b64", "#f8f9f9"],
                    },
                  ],
                }}
                options={doughnutOptions}
              />
            </DoughnutContainer>
          </Grid>
          <Grid container item xs={7} flexDirection="column">
            <Typography variant="subtitle1" marginTop={1} marginLeft={2}>
              Testade Objekt
            </Typography>
            <Grid item display="flex" marginLeft={2}>
              <Typography
                variant="h1"
                style={{
                  color: "#409b64",
                }}
              >
                {testedObjects.length}
              </Typography>
              <Typography marginTop={2} style={{ color: "grey" }}>
                /{instances.length}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={7} paddingLeft={2} style={{ alignItems: "center" }}>
          <DoughnutContainer>
            <Doughnut
              data={{
                datasets: [
                  {
                    data: notWorking > 0 || working > 0 ? [working, notWorking] : [100],
                    backgroundColor:
                      notWorking > 0 || working > 0 ? ["#409b64", "#00579d"] : ["#F8F9F9"],
                  },
                ],
              }}
              options={doughnutOptions}
            />
          </DoughnutContainer>
          <ContentContainer marginTop={1}>
            <Typography variant="subtitle1" paddingBottom={1}>
              Analys
            </Typography>
            {notWorking > 0 || working > 0 ? (
              <>
                <Grid item display="flex">
                  <PercentageWorking variant="caption">
                    {Math.round((working / total) * 100)}%
                  </PercentageWorking>
                  <Typography variant="caption">{working} Objekt funktionella</Typography>
                </Grid>
                <Grid item display="flex" paddingBottom={1}>
                  <PercentageNotWorking variant="caption">
                    {Math.round((notWorking / total) * 100)}%
                  </PercentageNotWorking>
                  <Typography variant="caption">{notWorking} Objekt ej funktionella</Typography>
                </Grid>
                <Typography variant="caption">
                  Objekt frånkopplade eller med batterinivå under 40% rekommenderas att bytas ut.
                </Typography>
              </>
            ) : (
              <Typography>Inga tester utfördes</Typography>
            )}
          </ContentContainer>
        </Grid>
      </ChartContainer>
      {failedTests.length > 0 && (
        <>
          <Typography sx={{ fontSize: "20px", fontWeight: "500", margin: "36px 0" }}>
            Rekommendationer på åtgärder
          </Typography>
          <TableSection sectionName={sectionName} instances={failedTests} />
        </>
      )}
    </Grid>
  );
};
