import { Accordion, Grid } from "@mui/material";
import styled from "@emotion/styled";

export const SummaryContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  alignItems: "center",
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderColor: theme.palette.grey[200],
}));

export const VerifiedContainer = styled(Grid)(({ theme }) => ({
  borderColor: "#61A3D2",
  borderRadius: 5,
  display: "flex",
  padding: 5,
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  breakAfter: "always",
  pageBreakAfter: "always",
  border: "none",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0px -6px 38px 0px rgba(156, 171, 194, 0.15)",
  marginTop: "36px",
  "&:first-child": {
    marginTop: 0,
  },
  "&:before": {
    opacity: 0,
  },
}));
