import React from "react";
import { Typography, Chip, Box, Grid } from "@mui/material";
import { ObjectFragment } from "../../../generated/api-client";
import { TestableObjectType } from ".prisma/client";
import { Badge } from "./Badge";
import { map } from "lodash";

const TranslateType: Record<TestableObjectType, string> = {
  Heat: "Värme",
  Smoke: "Rök",
  SmokeHeat: "Värme & rök",
  IO: "Kontrollfunktion",
  Bell: "Larmdon",
  Documentation: "Dokumentation",
  Other: "Övrigt",
  MCP: "Larmknapp",
  UnknownSensor: "Övrigt",
  Door: "Dörr",
  Ventilation: "Ventilation",
};

const badges: { [x in keyof ObjectFragment]?: string } = {
  cardAddress: "Card",
  physicalAddress: "Address",
};

export default function ObjectLabel({ object, big }: { object: ObjectFragment; big?: boolean }) {
  const name = [object.description1, object.description2].filter((x) => x.length > 0).join(" - ");

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant={big ? "h6" : "caption"} sx={{ pb: 1 }}>
        {name}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {map(
          badges,
          (name, field: keyof ObjectFragment) =>
            object[field] && <Badge key={field} field={name!} value={object[field]} />,
        )}
        <Chip
          label={TranslateType[object.type]}
          variant="outlined"
          size="small"
          sx={{ marginLeft: 1 }}
        />
      </Box>
    </Grid>
  );
}
