import React, { useState } from "react";
import l from "lodash";
import { useAddObjectToTestRunMutation, useObjectsInSiteQuery } from "../../generated/api-client";
import { useRouter } from "next/router";
import { ErrorAlert } from "../ErrorAlert";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ObjectLabel from "../TestInstance/Label/ObjectLabel";
import { ExpandMore as ExpandMoreIcon, Add as AddIcon } from "@mui/icons-material";
import { SectionName } from "../../utils/sectionName";

interface AddAnotherTestInstanceProps {
  sectionName: string;
  objectsAlreadyInSections: Array<string>;
}

export default function AddAnotherTestInstance({
  sectionName,
  objectsAlreadyInSections,
}: AddAnotherTestInstanceProps) {
  const router = useRouter();
  const { siteId, testId } = router.query;
  const [{ data, fetching: loading, error }, refetch] = useObjectsInSiteQuery({
    variables: { site: siteId as string },
    pause: !siteId,
  });
  let errorMessage = error?.message;

  const [{ error: addingObjectError }, addObject] = useAddObjectToTestRunMutation();

  const [objectAdding, setObjectAdding] = useState<string | null>(null);

  const addObjectToTestRun = async (objectId: string) => {
    setObjectAdding(objectId);
    addObject({
      testRunId: testId as string,
      objectId,
    })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => setObjectAdding(null));
  };

  const sectionObjects = l(data?.getSite?.testableObjects ?? [])
    .map((x) => ({ ...x, sectionName: new SectionName(x) }))
    .filter((x) => x.sectionName.toString() === sectionName)
    .value();

  if (data && (!sectionObjects || !sectionObjects.length)) {
    console.warn(`Unknown zone ${sectionName} in site ${siteId}`);
    errorMessage = "Något gick fel";
  }

  const addableObjects = (sectionObjects || []).filter(
    (x) => objectsAlreadyInSections.indexOf(x.id) == -1,
  );

  if (addingObjectError) {
    errorMessage = addingObjectError.message;
  }

  const allObjectsInTestRun = data && sectionObjects && addableObjects.length === 0;

  return (
    <Grid sx={{ marginTop: 3 }}>
      <Accordion
        onChange={(ev, expanded) => expanded && refetch()}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Lägg till fler objekt</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {loading && <LinearProgress />}
          {errorMessage && <ErrorAlert message={errorMessage || "Något gick fel"} />}
          {allObjectsInTestRun ? (
            <Alert severity="info">Inga tillgängliga objekt</Alert>
          ) : (
            <List>
              {(addableObjects || []).map((object) => (
                <ListItem key={object.id} onClick={() => addObjectToTestRun(object.id)}>
                  <ListItemButton>
                    <ListItemIcon>
                      {objectAdding === object.id ? <CircularProgress size={16} /> : <AddIcon />}
                    </ListItemIcon>
                    <ObjectLabel object={object} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
