import { Grid, Box } from "@mui/material";
import styled from "@emotion/styled";

export const ContentContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "auto",
  padding: 10,
  "@media print": {
    display: "block",
  },
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  height: 90,
  display: "flex",
  boxShadow: "0px -2px 18px 3px rgba(0,0,0,0.05)",
}));
