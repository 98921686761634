import { Grid, TextField, ToggleButton, Box } from "@mui/material";
import styled from "@emotion/styled";

export const TestInstanceContainer = styled(Box)(({ theme }) => ({
  padding: "10px",
  border: "1px solid #ccc",
  margin: "10px 0 20px 0",
  borderRadius: "10px",
  boxShadow: "0 0 20px #cccccc38",
  "&:not(:last-child)": {
    // borderBottom: `1px solid #eee`,
    paddingBottom: 10,
  },
}));

export const Container = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  // paddingTop: 20,
  "@media (max-width: 500px)": {
    flexDirection: "column",
  },
}));

export const HeaderContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: 0,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: 20,
  paddingTop: 10,
  "& fieldset": {
    borderRadius: 20,
  },
}));

export const ButtonsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  marginLeft: 10,
  "@media (max-width: 500px)": {
    marginTop: 20,
  },
}));

const ButtonStyle = {
  borderRadius: 20,
  marginRight: 10,
  minWidth: 55,
};

export const ButtonLikeBox = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.palette.secondary.main,
  ...ButtonStyle,
}));

const StyledIconButton = styled(ToggleButton)(({ theme }) => ButtonStyle);

export const ThumbsUpButton = styled(StyledIconButton)(({ theme }) => ({
  "&&.Mui-selected": {
    backgroundColor: "#348C51",
    color: "white",
  },
}));

export const ThumbsDownButton = styled(StyledIconButton)(({ theme }) => ({
  "&&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const SkipButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: 20,
  "&&.Mui-selected": {
    backgroundColor: "#384145",
    color: theme.palette.common.white,
  },
}));
