import React, { useState } from "react";
import { useRouter } from "next/router";
import {
  Typography,
  CircularProgress,
  Grid,
  Modal,
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { ArrowForward, Visibility, Send } from "@mui/icons-material/";

import {
  Container,
  SubmittedContainer,
  CaptionText,
  InfoText,
  StyledReportButton,
  StyledPreviewButton,
  StyledTextField,
} from "./styles";
import { Page } from "../../Page";
import { TabEnum } from "../enum";
import { FooterNavButton, NoteType } from "..";
import { ErrorAlert } from "../../ErrorAlert";
import { FooterContainer, LoadingContainer, StyledButton } from "../style";
import {
  TestProtocolFragment,
  useShareTestReportMutation,
  useSubmitTestRunMutation,
} from "../../../generated/api-client";
import { NoteSection } from "../..";

interface IFooter {
  handleTabChange: (event: React.SyntheticEvent, newValue: TabEnum) => void;
}

const Footer = ({ handleTabChange }: IFooter) => {
  return (
    <FooterContainer>
      <FooterNavButton direction="back" target={TabEnum.NOTES} {...{ handleTabChange }} />
    </FooterContainer>
  );
};

interface IReport {
  protocol: TestProtocolFragment | undefined;
  handleTabChange: (event: React.SyntheticEvent, newValue: TabEnum) => void;
  testSubmitted: boolean;
}

export const Report = ({ protocol, handleTabChange, testSubmitted }: IReport) => {
  const router = useRouter();
  const { testId } = router.query;

  const [open, setOpen] = useState(false);
  const togglePreviewAction = () => setOpen(!open);

  const [{ data, fetching: loading, error }, submitTest] = useSubmitTestRunMutation();
  const submitTestRunAction = async () => {
    await submitTest({
      testRun: testId as string,
    });
  };

  if (loading)
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );

  return (
    <Page title="Rapport" footer={<Footer handleTabChange={handleTabChange} />}>
      {error && <ErrorAlert message={error.message || "Något gick fel"} />}
      {data?.submitTestRun.error?.__typename === "TestRunSubmissionIncomplete" && (
        <ErrorAlert
          message={data?.submitTestRun.error?.message || "Can't submit incomplete test"}
          duration={3000}
        />
      )}
      {data?.submitTestRun.error?.__typename === "TestRunSubmissionAlreadyDone" && (
        <ErrorAlert
          message={data?.submitTestRun.error?.message || "Can't submit test twice"}
          duration={3000}
        />
      )}
      {testSubmitted ? (
        <Container>
          <SubmittedContainer>
            <CaptionText variant="subtitle2">Grymt!</CaptionText>
            <Typography variant="h4">Rapport klar</Typography>
            <InfoText variant="subtitle1">Bra jobbat!</InfoText>
            <StyledButton
              variant="outlined"
              startIcon={<ArrowForward />}
              onClick={() => router.replace("/")}
            >
              Gå till översikt
            </StyledButton>
            <StyledPreviewButton
              variant="contained"
              startIcon={<Visibility color="primary" />}
              onClick={togglePreviewAction}
              size="large"
              sx={{ mt: 2 }}
            >
              Förhandsgranska
            </StyledPreviewButton>
          </SubmittedContainer>
        </Container>
      ) : (
        <Grid container justifyContent="space-between" sx={{ maxWidth: 1400, margin: "0 auto" }}>
          <NoteSection
            noteType={NoteType.Client}
            header="Anteckning till kund"
            note="Detta är synligt för kund och till alla som har access till denna rapport, inklusive internt hos Consilium"
            value={protocol?.noteClient ?? ""}
            disabled={testSubmitted}
          />
          <NoteSection
            noteType={NoteType.Actions}
            header="Ytterligare rekommenderade åtgärder"
            note="Ytterligare rekommendationer som du vill inkludera i rapporten some inte passar någon annanstans"
            value={protocol?.noteActions ?? ""}
            disabled={testSubmitted}
          />
          <Grid container direction="row" flexDirection="row" paddingTop={2}>
            <StyledPreviewButton
              variant="contained"
              startIcon={<Visibility color="primary" />}
              onClick={togglePreviewAction}
              size="large"
            >
              Förhandsgranska
            </StyledPreviewButton>
            <StyledReportButton
              variant="contained"
              startIcon={<Send />}
              size="large"
              onClick={submitTestRunAction}
            >
              Skicka rapport
            </StyledReportButton>
          </Grid>
        </Grid>
      )}

      <ShareReport testRunId={testId as string} />

      <Modal
        open={open}
        onClose={togglePreviewAction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          onClick={togglePreviewAction}
          container
          justifyContent="center"
          alignItems="center"
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <iframe
            src={router.asPath + "/report?render=iframe"}
            style={{ height: "85%", width: "85%", borderRadius: 5 }}
          ></iframe>
        </Grid>
      </Modal>
    </Page>
  );
};

interface ShareReportProps {
  testRunId: string;
}

const ShareReport = ({ testRunId }: ShareReportProps) => {
  const [{ data, fetching: loading, error }, shareTest] = useShareTestReportMutation();
  const shareTestRun = async () => {
    await shareTest({
      testRun: testRunId,
    });
  };

  return (
    <Grid>
      <Card sx={{ m: "2em auto", maxWidth: 500 }}>
        <CardContent>
          <Typography variant="h5">Dela rapport</Typography>
          {error && <ErrorAlert message={error.message || "Något gick fel"} />}
          {data?.shareTestReport && (
            <Grid sx={{ pt: 1 }}>
              <TextField
                aria-readonly
                fullWidth
                variant="standard"
                onFocus={(ev) => {
                  ev.target.select();
                  navigator.clipboard.writeText(ev.target.value);
                }}
                value={`${window.location.origin}/report/${data.shareTestReport.id}`}
                helperText={`giltig till ${new Date(data.shareTestReport.expiresAt).toLocaleString(
                  "sv",
                )}`}
              />
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Button disabled={loading} onClick={shareTestRun}>
            {loading && <CircularProgress size={16} />}
            Skapa länk
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
